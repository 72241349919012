import VueGoogleMaps from '@fawmi/vue-google-maps'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig()
  nuxtApp.vueApp.use(VueGoogleMaps, {
    load: {
      key: runtimeConfig.public.GOOGLE_MAPS_API_KEY,
      libraries: 'places',
      loading: 'async',
    },
  })
})
