import type { Pinia } from 'pinia'
import useAuthStore from '@/store/auth'
import { roleKey, tokenKey } from '@/constants/auth'
import type { ROLES_CODES } from '~/constants/roles'

export default defineNuxtPlugin(nuxtApp => {
  const pinia = nuxtApp.$pinia as Pinia

  const { setIsAuth, setRole } = useAuthStore(pinia)

  const token = useStatefulCookie(tokenKey)

  const role = useStatefulCookie<ROLES_CODES>(roleKey)

  if (token.value) {
    setIsAuth(true)
    setRole(role.value)
  }
})
