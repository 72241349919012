import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/usr/src/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/usr/src/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/usr/src/app/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/usr/src/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import vue_query_wrmMkNpEpe from "/usr/src/app/plugins/vue-query.ts";
import api_GFfDXud5Cr from "/usr/src/app/plugins/api.ts";
import auth_store_init_DIiTiSNOQY from "/usr/src/app/plugins/auth-store-init.ts";
import chartjs_client_N7GULTnRpX from "/usr/src/app/plugins/chartjs.client.ts";
import maska_UHaKf2z1iQ from "/usr/src/app/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import vue_google_maps_client_gpNQwFOD8U from "/usr/src/app/plugins/vue-google-maps.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_tbFNToZNim,
  plugin_tMGwffvjBc,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  vue_query_wrmMkNpEpe,
  api_GFfDXud5Cr,
  auth_store_init_DIiTiSNOQY,
  chartjs_client_N7GULTnRpX,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  vue_google_maps_client_gpNQwFOD8U
]