import { useQuery } from '@tanstack/vue-query'
import { queries } from '~/keys/index'
import { DEFAULT_CACHE_TIME, RELEASE_COMPONENTS_CODES } from '~/constants'
import type { SuccessResponse } from '~/types/response'
import type { ReleaseResponse } from '~/types/common'

const useReleaseComponent = (code: RELEASE_COMPONENTS_CODES, enabled = true) => {
  const { fetch } = useAPIFetch()

  return useQuery({
    ...queries.versions.component(code),
    queryFn: () =>
      fetch<SuccessResponse<ReleaseResponse['components'][number]>>({
        method: 'GET',
        url: `/releases/components`,
        params: {
          code,
        },
      }),
    staleTime: DEFAULT_CACHE_TIME,
    enabled,
    retry: false,
    select: data => data.data,
  })
}

export default useReleaseComponent
