import { default as callbackIeMxzD36CkMeta } from "/usr/src/app/pages/auth/callback.vue?macro=true";
import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as index7Hom5UJ4cAMeta } from "/usr/src/app/pages/earnings/index.vue?macro=true";
import { default as earningsGQBlRg7g3bMeta } from "/usr/src/app/pages/earnings.vue?macro=true";
import { default as commissions4hmXzGCK8CMeta } from "/usr/src/app/pages/finances/commissions.vue?macro=true";
import { default as index1SKQEUEneAMeta } from "/usr/src/app/pages/finances/index.vue?macro=true";
import { default as payoutsYedJEPbrR3Meta } from "/usr/src/app/pages/finances/payouts.vue?macro=true";
import { default as financessu07cVajmzMeta } from "/usr/src/app/pages/finances.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indextuXjtfNIiCMeta } from "/usr/src/app/pages/invoices/index.vue?macro=true";
import { default as invoicesDhPruh9MQ7Meta } from "/usr/src/app/pages/invoices.vue?macro=true";
import { default as email7uelwrDrx5Meta } from "/usr/src/app/pages/login/email.vue?macro=true";
import { default as indexMqL0VaR3piMeta } from "/usr/src/app/pages/login/index.vue?macro=true";
import { default as indexxogUolLFKmMeta } from "/usr/src/app/pages/management/index.vue?macro=true";
import { default as permissions6t4qT7vcp8Meta } from "/usr/src/app/pages/management/permissions.vue?macro=true";
import { default as settingsl9ZJHuS7wzMeta } from "/usr/src/app/pages/management/settings.vue?macro=true";
import { default as usersKhvLs37fZCMeta } from "/usr/src/app/pages/management/users.vue?macro=true";
import { default as managementHMUz8f0RCHMeta } from "/usr/src/app/pages/management.vue?macro=true";
import { default as _91id_93hQyHbl5pAfMeta } from "/usr/src/app/pages/partners/[id].vue?macro=true";
import { default as indexTnxymMpf0QMeta } from "/usr/src/app/pages/partners/index.vue?macro=true";
import { default as partnershH4za4yi7CMeta } from "/usr/src/app/pages/partners.vue?macro=true";
import { default as documentsv0s3HuN706Meta } from "/usr/src/app/pages/profile/documents.vue?macro=true";
import { default as generalx5Acy7PGCaMeta } from "/usr/src/app/pages/profile/general.vue?macro=true";
import { default as index77UxeWDrkFMeta } from "/usr/src/app/pages/profile/index.vue?macro=true";
import { default as main2JnOvzTPptMeta } from "/usr/src/app/pages/profile/main.vue?macro=true";
import { default as paymentTLf02QP6k0Meta } from "/usr/src/app/pages/profile/payment.vue?macro=true";
import { default as securitycZ10txq3LJMeta } from "/usr/src/app/pages/profile/security.vue?macro=true";
import { default as profilempmbOOM2vzMeta } from "/usr/src/app/pages/profile.vue?macro=true";
import { default as _91id_93vcUPitdGkyMeta } from "/usr/src/app/pages/properties/[id].vue?macro=true";
import { default as indexUVT9AUKElHMeta } from "/usr/src/app/pages/properties/index.vue?macro=true";
import { default as indexyryyYDnZgJMeta } from "/usr/src/app/pages/property/[hash]/index.vue?macro=true";
import { default as _91id_93gxE6VCJUHVMeta } from "/usr/src/app/pages/property/[hash]/unit/[id].vue?macro=true";
import { default as _91id_93i2CNALzRKBMeta } from "/usr/src/app/pages/referrals/[id].vue?macro=true";
import { default as add_45referraly4k4HXC9OxMeta } from "/usr/src/app/pages/referrals/add-referral.vue?macro=true";
import { default as indexW9h9DWpOE5Meta } from "/usr/src/app/pages/referrals/index.vue?macro=true";
import { default as referralsBgcQJcu9O1Meta } from "/usr/src/app/pages/referrals.vue?macro=true";
import { default as indexTZHXTk2gM2Meta } from "/usr/src/app/pages/reset/index.vue?macro=true";
import { default as newMG3wLaVrsHMeta } from "/usr/src/app/pages/reset/new.vue?macro=true";
import { default as emailLrSJIRbWaAMeta } from "/usr/src/app/pages/signup/email.vue?macro=true";
import { default as indexZh0bVlLDf6Meta } from "/usr/src/app/pages/signup/index.vue?macro=true";
import { default as _91id_93huwIyHFRGJMeta } from "/usr/src/app/pages/users/[id].vue?macro=true";
export default [
  {
    name: callbackIeMxzD36CkMeta?.name ?? "auth-callback",
    path: callbackIeMxzD36CkMeta?.path ?? "/auth/callback",
    meta: callbackIeMxzD36CkMeta || {},
    alias: callbackIeMxzD36CkMeta?.alias || [],
    redirect: callbackIeMxzD36CkMeta?.redirect,
    component: () => import("/usr/src/app/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: dashboardhKii8fXWAxMeta?.name ?? "dashboard",
    path: dashboardhKii8fXWAxMeta?.path ?? "/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    alias: dashboardhKii8fXWAxMeta?.alias || [],
    redirect: dashboardhKii8fXWAxMeta?.redirect,
    component: () => import("/usr/src/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: earningsGQBlRg7g3bMeta?.name ?? undefined,
    path: earningsGQBlRg7g3bMeta?.path ?? "/earnings",
    meta: earningsGQBlRg7g3bMeta || {},
    alias: earningsGQBlRg7g3bMeta?.alias || [],
    redirect: earningsGQBlRg7g3bMeta?.redirect,
    component: () => import("/usr/src/app/pages/earnings.vue").then(m => m.default || m),
    children: [
  {
    name: index7Hom5UJ4cAMeta?.name ?? "earnings",
    path: index7Hom5UJ4cAMeta?.path ?? "",
    meta: index7Hom5UJ4cAMeta || {},
    alias: index7Hom5UJ4cAMeta?.alias || [],
    redirect: index7Hom5UJ4cAMeta?.redirect,
    component: () => import("/usr/src/app/pages/earnings/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: financessu07cVajmzMeta?.name ?? undefined,
    path: financessu07cVajmzMeta?.path ?? "/finances",
    meta: financessu07cVajmzMeta || {},
    alias: financessu07cVajmzMeta?.alias || [],
    redirect: financessu07cVajmzMeta?.redirect,
    component: () => import("/usr/src/app/pages/finances.vue").then(m => m.default || m),
    children: [
  {
    name: commissions4hmXzGCK8CMeta?.name ?? "finances-commissions",
    path: commissions4hmXzGCK8CMeta?.path ?? "commissions",
    meta: commissions4hmXzGCK8CMeta || {},
    alias: commissions4hmXzGCK8CMeta?.alias || [],
    redirect: commissions4hmXzGCK8CMeta?.redirect,
    component: () => import("/usr/src/app/pages/finances/commissions.vue").then(m => m.default || m)
  },
  {
    name: index1SKQEUEneAMeta?.name ?? "finances",
    path: index1SKQEUEneAMeta?.path ?? "",
    meta: index1SKQEUEneAMeta || {},
    alias: index1SKQEUEneAMeta?.alias || [],
    redirect: index1SKQEUEneAMeta?.redirect,
    component: () => import("/usr/src/app/pages/finances/index.vue").then(m => m.default || m)
  },
  {
    name: payoutsYedJEPbrR3Meta?.name ?? "finances-payouts",
    path: payoutsYedJEPbrR3Meta?.path ?? "payouts",
    meta: payoutsYedJEPbrR3Meta || {},
    alias: payoutsYedJEPbrR3Meta?.alias || [],
    redirect: payoutsYedJEPbrR3Meta?.redirect,
    component: () => import("/usr/src/app/pages/finances/payouts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invoicesDhPruh9MQ7Meta?.name ?? undefined,
    path: invoicesDhPruh9MQ7Meta?.path ?? "/invoices",
    meta: invoicesDhPruh9MQ7Meta || {},
    alias: invoicesDhPruh9MQ7Meta?.alias || [],
    redirect: invoicesDhPruh9MQ7Meta?.redirect,
    component: () => import("/usr/src/app/pages/invoices.vue").then(m => m.default || m),
    children: [
  {
    name: indextuXjtfNIiCMeta?.name ?? "invoices",
    path: indextuXjtfNIiCMeta?.path ?? "",
    meta: indextuXjtfNIiCMeta || {},
    alias: indextuXjtfNIiCMeta?.alias || [],
    redirect: indextuXjtfNIiCMeta?.redirect,
    component: () => import("/usr/src/app/pages/invoices/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: email7uelwrDrx5Meta?.name ?? "login-email",
    path: email7uelwrDrx5Meta?.path ?? "/login/email",
    meta: email7uelwrDrx5Meta || {},
    alias: email7uelwrDrx5Meta?.alias || [],
    redirect: email7uelwrDrx5Meta?.redirect,
    component: () => import("/usr/src/app/pages/login/email.vue").then(m => m.default || m)
  },
  {
    name: indexMqL0VaR3piMeta?.name ?? "login",
    path: indexMqL0VaR3piMeta?.path ?? "/login",
    meta: indexMqL0VaR3piMeta || {},
    alias: indexMqL0VaR3piMeta?.alias || [],
    redirect: indexMqL0VaR3piMeta?.redirect,
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: managementHMUz8f0RCHMeta?.name ?? undefined,
    path: managementHMUz8f0RCHMeta?.path ?? "/management",
    meta: managementHMUz8f0RCHMeta || {},
    alias: managementHMUz8f0RCHMeta?.alias || [],
    redirect: managementHMUz8f0RCHMeta?.redirect,
    component: () => import("/usr/src/app/pages/management.vue").then(m => m.default || m),
    children: [
  {
    name: indexxogUolLFKmMeta?.name ?? "management",
    path: indexxogUolLFKmMeta?.path ?? "",
    meta: indexxogUolLFKmMeta || {},
    alias: indexxogUolLFKmMeta?.alias || [],
    redirect: indexxogUolLFKmMeta?.redirect,
    component: () => import("/usr/src/app/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: permissions6t4qT7vcp8Meta?.name ?? "management-permissions",
    path: permissions6t4qT7vcp8Meta?.path ?? "permissions",
    meta: permissions6t4qT7vcp8Meta || {},
    alias: permissions6t4qT7vcp8Meta?.alias || [],
    redirect: permissions6t4qT7vcp8Meta?.redirect,
    component: () => import("/usr/src/app/pages/management/permissions.vue").then(m => m.default || m)
  },
  {
    name: settingsl9ZJHuS7wzMeta?.name ?? "management-settings",
    path: settingsl9ZJHuS7wzMeta?.path ?? "settings",
    meta: settingsl9ZJHuS7wzMeta || {},
    alias: settingsl9ZJHuS7wzMeta?.alias || [],
    redirect: settingsl9ZJHuS7wzMeta?.redirect,
    component: () => import("/usr/src/app/pages/management/settings.vue").then(m => m.default || m)
  },
  {
    name: usersKhvLs37fZCMeta?.name ?? "management-users",
    path: usersKhvLs37fZCMeta?.path ?? "users",
    meta: usersKhvLs37fZCMeta || {},
    alias: usersKhvLs37fZCMeta?.alias || [],
    redirect: usersKhvLs37fZCMeta?.redirect,
    component: () => import("/usr/src/app/pages/management/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: partnershH4za4yi7CMeta?.name ?? undefined,
    path: partnershH4za4yi7CMeta?.path ?? "/partners",
    meta: partnershH4za4yi7CMeta || {},
    alias: partnershH4za4yi7CMeta?.alias || [],
    redirect: partnershH4za4yi7CMeta?.redirect,
    component: () => import("/usr/src/app/pages/partners.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93hQyHbl5pAfMeta?.name ?? "partners-id",
    path: _91id_93hQyHbl5pAfMeta?.path ?? ":id()",
    meta: _91id_93hQyHbl5pAfMeta || {},
    alias: _91id_93hQyHbl5pAfMeta?.alias || [],
    redirect: _91id_93hQyHbl5pAfMeta?.redirect,
    component: () => import("/usr/src/app/pages/partners/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTnxymMpf0QMeta?.name ?? "partners",
    path: indexTnxymMpf0QMeta?.path ?? "",
    meta: indexTnxymMpf0QMeta || {},
    alias: indexTnxymMpf0QMeta?.alias || [],
    redirect: indexTnxymMpf0QMeta?.redirect,
    component: () => import("/usr/src/app/pages/partners/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profilempmbOOM2vzMeta?.name ?? undefined,
    path: profilempmbOOM2vzMeta?.path ?? "/profile",
    meta: profilempmbOOM2vzMeta || {},
    alias: profilempmbOOM2vzMeta?.alias || [],
    redirect: profilempmbOOM2vzMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: documentsv0s3HuN706Meta?.name ?? "profile-documents",
    path: documentsv0s3HuN706Meta?.path ?? "documents",
    meta: documentsv0s3HuN706Meta || {},
    alias: documentsv0s3HuN706Meta?.alias || [],
    redirect: documentsv0s3HuN706Meta?.redirect,
    component: () => import("/usr/src/app/pages/profile/documents.vue").then(m => m.default || m)
  },
  {
    name: generalx5Acy7PGCaMeta?.name ?? "profile-general",
    path: generalx5Acy7PGCaMeta?.path ?? "general",
    meta: generalx5Acy7PGCaMeta || {},
    alias: generalx5Acy7PGCaMeta?.alias || [],
    redirect: generalx5Acy7PGCaMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/general.vue").then(m => m.default || m)
  },
  {
    name: index77UxeWDrkFMeta?.name ?? "profile",
    path: index77UxeWDrkFMeta?.path ?? "",
    meta: index77UxeWDrkFMeta || {},
    alias: index77UxeWDrkFMeta?.alias || [],
    redirect: index77UxeWDrkFMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: main2JnOvzTPptMeta?.name ?? "profile-main",
    path: main2JnOvzTPptMeta?.path ?? "main",
    meta: main2JnOvzTPptMeta || {},
    alias: main2JnOvzTPptMeta?.alias || [],
    redirect: main2JnOvzTPptMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/main.vue").then(m => m.default || m)
  },
  {
    name: paymentTLf02QP6k0Meta?.name ?? "profile-payment",
    path: paymentTLf02QP6k0Meta?.path ?? "payment",
    meta: paymentTLf02QP6k0Meta || {},
    alias: paymentTLf02QP6k0Meta?.alias || [],
    redirect: paymentTLf02QP6k0Meta?.redirect,
    component: () => import("/usr/src/app/pages/profile/payment.vue").then(m => m.default || m)
  },
  {
    name: securitycZ10txq3LJMeta?.name ?? "profile-security",
    path: securitycZ10txq3LJMeta?.path ?? "security",
    meta: securitycZ10txq3LJMeta || {},
    alias: securitycZ10txq3LJMeta?.alias || [],
    redirect: securitycZ10txq3LJMeta?.redirect,
    component: () => import("/usr/src/app/pages/profile/security.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93vcUPitdGkyMeta?.name ?? "properties-id",
    path: _91id_93vcUPitdGkyMeta?.path ?? "/properties/:id()",
    meta: _91id_93vcUPitdGkyMeta || {},
    alias: _91id_93vcUPitdGkyMeta?.alias || [],
    redirect: _91id_93vcUPitdGkyMeta?.redirect,
    component: () => import("/usr/src/app/pages/properties/[id].vue").then(m => m.default || m)
  },
  {
    name: indexUVT9AUKElHMeta?.name ?? "properties",
    path: indexUVT9AUKElHMeta?.path ?? "/properties",
    meta: indexUVT9AUKElHMeta || {},
    alias: indexUVT9AUKElHMeta?.alias || [],
    redirect: indexUVT9AUKElHMeta?.redirect,
    component: () => import("/usr/src/app/pages/properties/index.vue").then(m => m.default || m)
  },
  {
    name: indexyryyYDnZgJMeta?.name ?? "property-hash",
    path: indexyryyYDnZgJMeta?.path ?? "/property/:hash()",
    meta: indexyryyYDnZgJMeta || {},
    alias: indexyryyYDnZgJMeta?.alias || [],
    redirect: indexyryyYDnZgJMeta?.redirect,
    component: () => import("/usr/src/app/pages/property/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93gxE6VCJUHVMeta?.name ?? "property-hash-unit-id",
    path: _91id_93gxE6VCJUHVMeta?.path ?? "/property/:hash()/unit/:id()",
    meta: _91id_93gxE6VCJUHVMeta || {},
    alias: _91id_93gxE6VCJUHVMeta?.alias || [],
    redirect: _91id_93gxE6VCJUHVMeta?.redirect,
    component: () => import("/usr/src/app/pages/property/[hash]/unit/[id].vue").then(m => m.default || m)
  },
  {
    name: referralsBgcQJcu9O1Meta?.name ?? undefined,
    path: referralsBgcQJcu9O1Meta?.path ?? "/referrals",
    meta: referralsBgcQJcu9O1Meta || {},
    alias: referralsBgcQJcu9O1Meta?.alias || [],
    redirect: referralsBgcQJcu9O1Meta?.redirect,
    component: () => import("/usr/src/app/pages/referrals.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93i2CNALzRKBMeta?.name ?? "referrals-id",
    path: _91id_93i2CNALzRKBMeta?.path ?? ":id()",
    meta: _91id_93i2CNALzRKBMeta || {},
    alias: _91id_93i2CNALzRKBMeta?.alias || [],
    redirect: _91id_93i2CNALzRKBMeta?.redirect,
    component: () => import("/usr/src/app/pages/referrals/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45referraly4k4HXC9OxMeta?.name ?? "referrals-add-referral",
    path: add_45referraly4k4HXC9OxMeta?.path ?? "add-referral",
    meta: add_45referraly4k4HXC9OxMeta || {},
    alias: add_45referraly4k4HXC9OxMeta?.alias || [],
    redirect: add_45referraly4k4HXC9OxMeta?.redirect,
    component: () => import("/usr/src/app/pages/referrals/add-referral.vue").then(m => m.default || m)
  },
  {
    name: indexW9h9DWpOE5Meta?.name ?? "referrals",
    path: indexW9h9DWpOE5Meta?.path ?? "",
    meta: indexW9h9DWpOE5Meta || {},
    alias: indexW9h9DWpOE5Meta?.alias || [],
    redirect: indexW9h9DWpOE5Meta?.redirect,
    component: () => import("/usr/src/app/pages/referrals/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexTZHXTk2gM2Meta?.name ?? "reset",
    path: indexTZHXTk2gM2Meta?.path ?? "/reset",
    meta: indexTZHXTk2gM2Meta || {},
    alias: indexTZHXTk2gM2Meta?.alias || [],
    redirect: indexTZHXTk2gM2Meta?.redirect,
    component: () => import("/usr/src/app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: newMG3wLaVrsHMeta?.name ?? "reset-new",
    path: newMG3wLaVrsHMeta?.path ?? "/reset/new",
    meta: newMG3wLaVrsHMeta || {},
    alias: newMG3wLaVrsHMeta?.alias || [],
    redirect: newMG3wLaVrsHMeta?.redirect,
    component: () => import("/usr/src/app/pages/reset/new.vue").then(m => m.default || m)
  },
  {
    name: emailLrSJIRbWaAMeta?.name ?? "signup-email",
    path: emailLrSJIRbWaAMeta?.path ?? "/signup/email",
    meta: emailLrSJIRbWaAMeta || {},
    alias: emailLrSJIRbWaAMeta?.alias || [],
    redirect: emailLrSJIRbWaAMeta?.redirect,
    component: () => import("/usr/src/app/pages/signup/email.vue").then(m => m.default || m)
  },
  {
    name: indexZh0bVlLDf6Meta?.name ?? "signup",
    path: indexZh0bVlLDf6Meta?.path ?? "/signup",
    meta: indexZh0bVlLDf6Meta || {},
    alias: indexZh0bVlLDf6Meta?.alias || [],
    redirect: indexZh0bVlLDf6Meta?.redirect,
    component: () => import("/usr/src/app/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93huwIyHFRGJMeta?.name ?? "users-id",
    path: _91id_93huwIyHFRGJMeta?.path ?? "/users/:id()",
    meta: _91id_93huwIyHFRGJMeta || {},
    alias: _91id_93huwIyHFRGJMeta?.alias || [],
    redirect: _91id_93huwIyHFRGJMeta?.redirect,
    component: () => import("/usr/src/app/pages/users/[id].vue").then(m => m.default || m)
  }
]