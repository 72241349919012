import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  BarController,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js'

export default defineNuxtPlugin(() => {
  ChartJS.register([
    Tooltip,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
  ])
})
