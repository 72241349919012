import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import persist_45query_45global from "/usr/src/app/middleware/persist-query.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  persist_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-auth-protected": () => import("/usr/src/app/middleware/is-auth-protected.ts"),
  "is-auth-route": () => import("/usr/src/app/middleware/is-auth-route.ts"),
  "is-ceo": () => import("/usr/src/app/middleware/is-ceo.ts"),
  "is-super-admin-or-ceo": () => import("/usr/src/app/middleware/is-super-admin-or-ceo.ts"),
  "is-super-admin": () => import("/usr/src/app/middleware/is-super-admin.ts"),
  "mobile-user-profile": () => import("/usr/src/app/middleware/mobile-user-profile.ts")
}