<template>
  <div class="app">
    <VueQueryDevtools />
    <NuxtLayout>
      <NuxtPage />
      <DynamicPopup />
    </NuxtLayout>
    <UISnackBanner />
    <FeaturesNetworkChecker />
  </div>
</template>
<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'

const { checkVersion } = useVersionCheck()

onMounted(() => checkVersion())
</script>
<style lang="scss">
@import '@/styles/variables.scss';

.page-enter-active,
.page-leave-active,
.layout-enter-active,
.layout-leave-active {
  transition: $base-transition opacity;
}

.page-enter-from,
.page-leave-to,
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}
</style>
