import { PERSIST_QUERY } from '~/constants'

export default defineNuxtRouteMiddleware((to, from) => {
  const queryFromKeys = Object.keys(from.query)
  const queryToKeys = Object.keys(to.query)

  // Save some query params on route change
  if (
    queryFromKeys.length &&
    !queryToKeys.length &&
    queryFromKeys.includes(PERSIST_QUERY.BCLICKID) &&
    !queryFromKeys.includes('tracked')
  ) {
    return navigateTo({
      path: to.path,
      hash: to.hash,
      params: to.params,
      query: from.query,
    })
  }
})
