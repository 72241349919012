<template>
  <div class="error-page">
    <UISidebar disable-fetch :class="{ 'sidebar--open': isMenuVisible }" @close-menu="isMenuVisible = false" />
    <UIMobileHeader v-if="isMobile" @open-menu="isMenuVisible = true" />
    <FeaturesDefaultContainer class="error-page__container">
      <div class="error-page__illustration">
        <ClientOnly>
          <Component :is="currentError.illustration" />
        </ClientOnly>
      </div>
      <div class="error-page__content">
        <h1 class="error-page__content-title">{{ currentError.title }}</h1>
        <p class="error-page__content-description">{{ currentError.description }}</p>
        <div v-if="!currentError.hideStatusCode" class="error-page__content-code">{{ error.statusCode }}</div>
      </div>
    </FeaturesDefaultContainer>
    <FeaturesNetworkChecker />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import Ghost from '@/assets/illustrations/ghost.svg?component'
import PowerOutlet from '@/assets/illustrations/power-outlet.svg?component'
import EarthNoSignal from '@/assets/illustrations/earth-no-signal.svg?component'

const props = defineProps<{
  error: NuxtError
}>()

type ErrorIllustration = {
  title: string
  description: string
  illustration: any
  hideStatusCode?: boolean
}

const statusCodeIllustrationsMap = new Map<number, ErrorIllustration>([
  [
    400,
    {
      title: 'Bad request',
      description: 'Oops! Looks like the request is invalid. Please check your request and try again.',
      illustration: Ghost,
    },
  ],
  [
    404,
    {
      title: 'Not found',
      description: "Oops! We can't find what you're looking for. Please check your request and try again.",
      illustration: Ghost,
    },
  ],
  [
    500,
    {
      title: 'Internal Server Error',
      description:
        "Oops! Something went wrong with the server. We're already working on it. Please try again in a little while.",
      illustration: PowerOutlet,
    },
  ],
  [
    503,
    {
      title: 'Oops! Connection Issue',
      description: `Oops! It seems internet is playing hide and seek. 
        It's time to give your Wi-Fi a pep talk or maybe just try refreshing the page.`,
      illustration: EarthNoSignal,
      hideStatusCode: true,
    },
  ],
])

const { isMobile } = useAppBreakpoints()

const isMenuVisible = ref(false)

const { lock, unlock } = useBodyScrollLock()

watch(
  () => isMenuVisible.value,
  (value: boolean) => {
    if (!isMobile) return
    if (value) {
      lock()
    } else {
      unlock()
    }
  },
)

const currentError = computed(() => {
  const error = statusCodeIllustrationsMap.get(props.error.statusCode)
  return error || statusCodeIllustrationsMap.get(500)!
})

console.log(props.error.message)
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.error-page {
  display: flex;
  min-height: 100dvh;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    padding-left: 16px !important;
    padding-right: 16px !important;

    @media screen and (max-width: 1023px) {
      padding-bottom: 56px !important;
    }
  }

  &__illustration {
    height: 250px;
    display: flex;
    align-items: flex-end;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 460px;
    width: 100%;
    position: relative;
    padding-top: 16px;

    &:has(.error-page__content-code) {
      .error-page__content-title {
        padding-top: 50px;

        @media screen and (max-width: 1023px) {
          font-size: 24px !important;
          padding-top: 16px;
        }
      }
    }

    &-title {
      @include headline4();
      font-size: 32px !important;
      line-height: 120% !important;
      color: $additional-dark-violet-120;
      z-index: 2;
      text-align: center;
      text-wrap: balance;
    }

    &-description {
      @include body1();
      font-size: 24px;
      color: $black-100;
      text-align: center;
      z-index: 2;
      line-height: 120% !important;

      @media screen and (max-width: 1023px) {
        font-size: 18px;
      }
    }

    &-code {
      display: block;
      color: $additional-dark-violet-20;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 231px;
      line-height: 1;
      font-weight: 900;

      @media screen and (max-width: 1023px) {
        font-size: 120px;
      }
    }
  }
}
</style>
