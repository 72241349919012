import { version } from '@/package.json'
import { RELEASE_COMPONENTS_CODES } from '~/constants'
import useAuthStore from '~/store/auth'

const useVersionCheck = () => {
  const authStore = useAuthStore()
  const isDevelopment = process.env.NODE_ENV === 'development'
  // TODO: temporary solution, need to clarify with .env
  const isDevelopmentServer = window?.origin === 'https://partners.thrivestate.dev'
  const { isSuperAdmin, isAuth } = storeToRefs(authStore)

  const { data: feComponent, refetch } = useReleaseComponent(RELEASE_COMPONENTS_CODES.FE_PARTNERS_PORTAL, isAuth.value)
  const { mutateAsync: logout } = useLogout()

  const checkVersion = async () => {
    if (!isAuth.value) {
      console.info('User is not authenticated, no need to check version')
      return
    }

    await refetch()

    if (!feComponent.value) {
      console.info('Frontend component is not found, returning')
      return
    }

    // Frontend is not updated
    if (feComponent.value.version !== version && !isSuperAdmin.value && !isDevelopment && !isDevelopmentServer) {
      await logout()
      return window.location.reload()
    }

    return true
  }

  return {
    checkVersion,
  }
}

export default useVersionCheck
