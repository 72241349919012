import { stringify } from 'qs'
import { tokenKey } from '~/constants/auth'

/* eslint-disable no-console */
export default defineNuxtPlugin(nuxtApp => {
  const token = useStatefulCookie(tokenKey)
  const runtimeConfig = useRuntimeConfig()

  const { logout } = useSuccessfulLogout()

  const { mutateAsync: requestLogout } = useLogout()

  const api = $fetch.create({
    baseURL: runtimeConfig.public.API_URL,
    retry: 0,
    onRequest({ options }) {
      if (token.value) {
        const headers = (options.headers ||= {})
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${token.value}`])
          headers.push(['Accept', 'application/json'])
        } else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${token.value}`)
          headers.set('Accept', 'application/json')
        } else {
          headers.Authorization = `Bearer ${token.value}`
          headers.Accept = 'application/json'
        }
      }

      if (options.params) {
        const convertedOptions = new URLSearchParams(stringify(options.params))
        options.params = Object.fromEntries(convertedOptions)
      }
    },
    async onResponseError(error) {
      const status = error.response.status
      const isLoginRequest = error.response.url.includes('login')
      const isLogoutRequest = error.response.url.includes('logout')

      if (status === 401) {
        console.log('Unauthorized')

        if (isLoginRequest) {
          return
        }

        await nuxtApp.runWithContext(async () => {
          if (isLogoutRequest) {
            // Logout request returned 401, so we can just logout
            await logout()
          } else {
            await requestLogout()
          }
        })
      }

      if (status === 404) {
        console.log('Page not found')

        throw createError({
          statusCode: 404,
          message: 'Resource not found',
        })
      }

      if (status === 403) {
        console.log('Access denied')

        throw createError({
          statusCode: 403,
          message: 'Access denied',
        })
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})
